.call-to-action-section{
    padding: 8.8rem 0;
    background-color: var(--secondary-color);
    div{
        text-align: center;
        h2,h3{
            color: var(--white-color);
        }
        a{
            font-size: var(--fz-h5);
            color: var(--black-color);
            text-decoration: none;
        }
    }
    div:first-child{            
        margin-bottom: 2.8rem;
    }    
    .grid-cta{
        display: grid;
        grid-template-columns: 1fr;
    }
}

// Small devices 
@media (min-width: 576px) {

}

// Medium devices 
@media (min-width: 768px) {

}

// Large devices
@media (min-width: 992px) {
    .call-to-action-section{        
        div:first-child{
            text-align: left;
            margin-bottom: 0;
        }
        div:last-child{
            text-align: center;
        }
        .grid-cta{
            display: grid;
            grid-template-columns: 60% 1fr;
        }
    }
}

// Extra large devices
@media (min-width: 1200px) {
    .call-to-action-section{
        .grid-cta{
            display: grid;
            grid-template-columns: 70% 1fr;
        }
    }
}

// Extra Extra large devices 
@media (min-width: 1400px) {
    .call-to-action-section{
        .grid-cta{
            display: grid;
            grid-template-columns: 75% 1fr;
        }
    }
}