.call-to-action-section {
  padding: 8.8rem 0;
  background-color: var(--secondary-color);
}
.call-to-action-section div {
  text-align: center;
}
.call-to-action-section div h2, .call-to-action-section div h3 {
  color: var(--white-color);
}
.call-to-action-section div a {
  font-size: var(--fz-h5);
  color: var(--black-color);
  text-decoration: none;
}
.call-to-action-section div:first-child {
  margin-bottom: 2.8rem;
}
.call-to-action-section .grid-cta {
  display: grid;
  grid-template-columns: 1fr;
}

@media (min-width: 992px) {
  .call-to-action-section div:first-child {
    text-align: left;
    margin-bottom: 0;
  }
  .call-to-action-section div:last-child {
    text-align: center;
  }
  .call-to-action-section .grid-cta {
    display: grid;
    grid-template-columns: 60% 1fr;
  }
}
@media (min-width: 1200px) {
  .call-to-action-section .grid-cta {
    display: grid;
    grid-template-columns: 70% 1fr;
  }
}
@media (min-width: 1400px) {
  .call-to-action-section .grid-cta {
    display: grid;
    grid-template-columns: 75% 1fr;
  }
}